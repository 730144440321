import React from "react";

class TeamPage extends React.Component {
  render() {
    return <div className="section" id="team">
      <div className="text-container2 mt100">
        <h1>Team</h1> <br/>
        <div className="icon-list">
          <div className="icon-item">
            <img src="gallery/T1.png" width="100%" alt="coming-soon"/>
            <h2>
              <a href="https://twitter.com/Jezeysell" target="blank" className="link">Jez</a>
            </h2>
            <p>Founder, Marketing & Development</p>
          </div>

          <div className="icon-item">
            <img src="gallery/T2.png" width="100%" alt="coming-soon"/>
            <h2>
              <a href="https://eiis.de" target="blank" className="link">AlienMutant</a>
            </h2>
            <p>Founder, Artist & Frontend</p>
          </div>
        </div>

        <div className="icon-list">
          <div className="icon-item">
            <img src="gallery/T3.png" width="100%" alt="coming-soon"/>
            <h2>
              <a href="https://twitter.com/Jezeysell" target="blank" className="link">Kal</a>
            </h2>
            <p>Development</p>
          </div>

          <div className="icon-item">
            <img src="gallery/T4.png" width="100%" alt="coming-soon"/>
            <h2>
              <a href="https://twitter.com/Jezeysell" target="blank" className="link">Dee</a>
            </h2>
            <p>Strategy & Community</p>
          </div>
        </div>
      </div>
    </div>
  }
}

export default TeamPage;