import React from "react";

class FooterComponent extends React.Component {
  render() {
    return <div className="footer">
      <a href="https://twitter.com/cryptvz">
        <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="twitter" className="svg-inline--fa fa-twitter" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M459.4 151.7c.325 4.548 .325 9.097 .325 13.65 0 138.7-105.6 298.6-298.6 298.6-59.45 0-114.7-17.22-161.1-47.11 8.447 .974 16.57 1.299 25.34 1.299 49.06 0 94.21-16.57 130.3-44.83-46.13-.975-84.79-31.19-98.11-72.77 6.498 .974 12.99 1.624 19.82 1.624 9.421 0 18.84-1.3 27.61-3.573-48.08-9.747-84.14-51.98-84.14-102.1v-1.299c13.97 7.797 30.21 12.67 47.43 13.32-28.26-18.84-46.78-51.01-46.78-87.39 0-19.49 5.197-37.36 14.29-52.95 51.65 63.67 129.3 105.3 216.4 109.8-1.624-7.797-2.599-15.92-2.599-24.04 0-57.83 46.78-104.9 104.9-104.9 30.21 0 57.5 12.67 76.67 33.14 23.72-4.548 46.46-13.32 66.6-25.34-7.798 24.37-24.37 44.83-46.13 57.83 21.12-2.273 41.58-8.122 60.43-16.24-14.29 20.79-32.16 39.31-52.63 54.25z"></path></svg>
      </a>
      <a href="https://discord.com/invite/GSHMAPUFBG">
        <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="discord" className="svg-inline--fa fa-discord" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path fill="currentColor" d="M524.5 69.84a1.5 1.5 0 0 0 -.764-.7A485.1 485.1 0 0 0 404.1 32.03a1.816 1.816 0 0 0 -1.923 .91 337.5 337.5 0 0 0 -14.9 30.6 447.8 447.8 0 0 0 -134.4 0 309.5 309.5 0 0 0 -15.14-30.6 1.89 1.89 0 0 0 -1.924-.91A483.7 483.7 0 0 0 116.1 69.14a1.712 1.712 0 0 0 -.788 .676C39.07 183.7 18.19 294.7 28.43 404.4a2.016 2.016 0 0 0 .765 1.375A487.7 487.7 0 0 0 176 479.9a1.9 1.9 0 0 0 2.063-.676A348.2 348.2 0 0 0 208.1 430.4a1.86 1.86 0 0 0 -1.019-2.588 321.2 321.2 0 0 1 -45.87-21.85 1.885 1.885 0 0 1 -.185-3.126c3.082-2.309 6.166-4.711 9.109-7.137a1.819 1.819 0 0 1 1.9-.256c96.23 43.92 200.4 43.92 295.5 0a1.812 1.812 0 0 1 1.924 .233c2.944 2.426 6.027 4.851 9.132 7.16a1.884 1.884 0 0 1 -.162 3.126 301.4 301.4 0 0 1 -45.89 21.83 1.875 1.875 0 0 0 -1 2.611 391.1 391.1 0 0 0 30.01 48.81 1.864 1.864 0 0 0 2.063 .7A486 486 0 0 0 610.7 405.7a1.882 1.882 0 0 0 .765-1.352C623.7 277.6 590.9 167.5 524.5 69.84zM222.5 337.6c-28.97 0-52.84-26.59-52.84-59.24S193.1 219.1 222.5 219.1c29.67 0 53.31 26.82 52.84 59.24C275.3 310.1 251.9 337.6 222.5 337.6zm195.4 0c-28.97 0-52.84-26.59-52.84-59.24S388.4 219.1 417.9 219.1c29.67 0 53.31 26.82 52.84 59.24C470.7 310.1 447.5 337.6 417.9 337.6z"></path></svg>
      </a>
      {/* <!-- <a href="https://opensea.io">
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
    <path fill="currentColor" d="M256,6.1C118,6.1,6.1,118,6.1,256S118,505.9,256,505.9c138,0,249.9-111.9,249.9-249.9S394,6.1,256,6.1z M129.4,264.4
      l1.1-1.7l65-101.7c0.9-1.5,3.2-1.3,3.9,0.3c10.9,24.3,20.2,54.6,15.8,73.5c-1.9,7.8-7,18.3-12.8,28c-0.7,1.4-1.6,2.8-2.4,4.1
      c-0.4,0.6-1.1,1-1.8,1h-66.9C129.5,267.8,128.4,265.9,129.4,264.4z M419.2,299.4c0,0.9-0.6,1.7-1.4,2.1c-5,2.2-22.3,10.1-29.4,20
      c-18.3,25.4-32.2,61.8-63.5,61.8H194.6c-46.2,0-83.6-37.5-83.6-83.9V298c0-1.2,1-2.2,2.2-2.2h72.6c1.4,0,2.5,1.3,2.4,2.7
      c-0.5,4.7,0.4,9.6,2.6,13.9c4.3,8.8,13.2,14.2,22.9,14.2h35.9v-28.1h-35.5c-1.8,0-2.9-2.1-1.8-3.6c0.4-0.6,0.8-1.2,1.3-1.9
      c3.4-4.8,8.2-12.2,12.9-20.6c3.3-5.7,6.4-11.8,9-17.9c0.5-1.1,0.9-2.2,1.3-3.3c0.7-2,1.4-3.8,1.9-5.6c0.5-1.5,0.9-3.2,1.3-4.7
      c1.2-5.2,1.7-10.7,1.7-16.4c0-2.2-0.1-4.6-0.3-6.8c-0.1-2.4-0.4-4.9-0.7-7.3c-0.2-2.2-0.6-4.3-1-6.5c-0.5-3.3-1.2-6.5-2.1-9.8
      l-0.3-1.2c-0.6-2.2-1.1-4.4-1.8-6.6c-2-7-4.4-13.8-6.8-20.2c-0.9-2.5-1.9-5-3-7.4c-1.5-3.7-3.1-7-4.5-10.2c-0.7-1.4-1.3-2.7-2-4.1
      c-0.7-1.5-1.4-3-2.1-4.5c-0.5-1.1-1.1-2.1-1.5-3.2l-4.4-8.1c-0.6-1.1,0.4-2.4,1.6-2.1l27.5,7.4h0.1c0.1,0,0.1,0,0.1,0l3.6,1l4,1.1
      l1.5,0.4v-16.3c0-7.9,6.3-14.3,14.1-14.3c3.9,0,7.4,1.6,10,4.2c2.5,2.6,4.1,6.1,4.1,10.1v24.2l2.9,0.8c0.2,0.1,0.5,0.2,0.7,0.3
      c0.7,0.5,1.7,1.3,3.1,2.3c1,0.8,2.1,1.8,3.5,2.8c2.6,2.1,5.8,4.9,9.3,8c0.9,0.8,1.8,1.6,2.6,2.4c4.5,4.2,9.5,9,14.3,14.4
      c1.3,1.5,2.6,3.1,4,4.7c1.3,1.6,2.7,3.3,4,4.9c1.6,2.2,3.4,4.4,4.9,6.7c0.7,1.1,1.5,2.2,2.2,3.3c2,3,3.7,6,5.3,9.1
      c0.7,1.4,1.4,3,2,4.5c1.8,4.1,3.3,8.2,4.2,12.4c0.3,0.9,0.5,1.9,0.6,2.7v0.2c0.3,1.2,0.4,2.5,0.5,3.9c0.4,4.3,0.2,8.5-0.7,12.8
      c-0.4,1.8-0.9,3.5-1.5,5.4c-0.6,1.7-1.2,3.6-2,5.3c-1.5,3.6-3.4,7.1-5.5,10.5c-0.7,1.2-1.5,2.5-2.3,3.8c-0.9,1.3-1.8,2.5-2.6,3.7
      c-1.1,1.5-2.3,3.2-3.6,4.6c-1.1,1.5-2.2,3-3.5,4.4c-1.7,2-3.4,4-5.1,5.8c-1,1.2-2.1,2.4-3.3,3.5c-1.1,1.2-2.2,2.3-3.3,3.4
      c-1.7,1.7-3.2,3.1-4.4,4.2l-2.8,2.6c-0.4,0.4-1,0.6-1.5,0.6h-21.9v28.1h27.5c6.2,0,12-2.2,16.7-6.2c1.6-1.4,8.7-7.5,17-16.7
      c0.3-0.3,0.6-0.5,1.1-0.6l76-22c1.4-0.4,2.8,0.7,2.8,2.2V299.4z"/>
    </svg>     
      </a> --> */}
      <p>Copyright © 2021 Karmaheads. All rights reserved.</p>
    </div>
  }
}

export default FooterComponent;
