import React from "react";

class AboutPage extends React.Component {
  render() {
    return <div className="mt80">
      <div className="text-container">
        <img src="blitz.png" width="50" alt="coming-soon"/>
        <h1>Welcome to the CRYPTV Society</h1>
        <p>A community of collectors and like minded people. We celebrate art, tech, gaming and create this space for NFT lovers that want be part of something bigger. Each asset represents partial ownership of the DAO. 6969 uniquely generated CRYPTVZ, all with randomly distributed traits, some even with hidden perks. CRYPTVZ are here to create a lasting value for the community. <br/></p>
        <p>By capturing contemporary art and digital culture in their parallel universe CRYPTVZ embrace the whole NFT space and all the projects that influenced us to this point. Selected pieces are showcased in 28x24 pixel screens - stay tuned - a whole adventure behind the screens will unfold in the upcoming months. Exclusive benefits are waiting for the genesis CRYPTVZ collector.<br/>Coming up we will use the funds of this project to establish a sustainable, growing ecosystem including a token, utility, features with artists, generated art and much more!</p>
      </div>
    </div>
  }
}

export default AboutPage;