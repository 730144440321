import React from "react";

class RoadMapPage extends React.Component {
  render() {
    return <div className="container2 g1" id="roadmap">
    <div className="wrapper">
      <h1>ROADMAP</h1>
      <div className="image">
        <img src="head.png" className="img2" alt="coming-soon"/>
      </div>

      <ul className="sessions">
        <li>
          <div className="time">20% - Be on the lookout</div>
          <p>Airdrop of 10 CRYPTVZ to 10 early believers.</p><br/>
        </li>
        <li>
          <div className="time">40% - Shuffle Raffle</div>
          <p>4x times 1Ξ will be distributed randomly to holders. Winners will be announced on <a className="link" href="https://twitter.com/cryptvz" target="blank">Twitter</a> and <a className="link" href="https://discord.com/invite/GSHMAPUFBG" target="blank">Discord</a>. 
          </p><br/>
        </li>
        <li>
          <div className="time">60% - Blog day</div>
          <p>Lore of CRYPTVZ will be released. Become the writer of your own back story. Best Submissions will be rewarded.</p><br/>
        </li>
        <li>
          <div className="time">80% - Extra Dimension</div>
          <p>- Let's get into the 3D space. With our target hit of 80% CRYPTVZ will expand into the 3D space.<br/>- Proposal of post launch utility.</p><br/>
        </li>
        <li>
          <div className="time">100% - To the moon</div>
          <p>- A quartlerly roadmap is going to be voted by the community.<br/>- Post launch surprises are being prepared and airdropped shortly.</p><br/>
        </li>
        
      </ul>
    </div>
  </div> 
  }
}

export default RoadMapPage;