import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { Routes, BrowserRouter, Route } from 'react-router-dom';
import ScreensPage from './packages/screens/ScreensPage';
import NavigationBar from './packages/navigationbar/NavigationBar';
import Home from './packages/home/HomePage';
import FooterComponent from './packages/footer/FooterComponent';
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

ReactDOM.render(
  <BrowserRouter>
    <div>
      <NavigationBar/>
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/screens" element={<ScreensPage />} />
      </Routes>
      <ScrollToTop />
      <FooterComponent/>
    </div>
  </BrowserRouter>,
  document.getElementById('root')
);

function ScrollToTop() {
  const { hash } = useLocation();

  useEffect(() => {
    if (hash.length === 0) {
      window.scrollTo(0, 0);
    }
  }, [hash]);

  return null;
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();