import React from "react";
import startPixelEditor from "./editor";

class ScreensPage extends React.Component {
  constructor() {
    super();
    this.editorRef = React.createRef();
  }
  render() {
    return <div className="mt80">
      <div className="text-container">
        <img src="blitz.png" width="50" alt="coming-soon"/>
        <h1>Screen content competition</h1>
        <p>Get creative and draw whatever you think looks best in the cryptv collection.<br/><br/></p>

        <p>We aim for positive nostalgic screen content that has shaped our generation in a good way. We thought that like this we will diversify our collection and engange with our community.<br/><br/> It's simple - Best submissions will enter into our collection and top 10 will be rewarded. Follow these steps: </p>
        <ul>
          <li><p>1. Create your artwork</p></li>
          <li><p>2. Give your artwork a name how the trait should be called.</p></li>
          <li><p>3. Download it and post it on Twitter using the #Cryptvz hashtag.</p></li><br/><br/>
          <li><p>4. Upload your ideas to our <a className="link" href="https://discord.com/invite/GSHMAPUFBG" target="blank">Discord</a> into trait-ideas.</p></li>
          <br/>
          <br/>
        </ul>
        <p>Good luck, we are excited receiving your submissions!</p>
        <br/><br/>
        <div id="editor" ref={this.editorRef}/>
      </div>
    </div>
  }

  componentDidMount() {
    const childEditor = startPixelEditor({});
    this.editorRef.current.appendChild(childEditor);
  }
}

export default ScreensPage;