import React, { useState } from "react";
import ReactDOM from 'react-dom';
import AboutPage from "../about/AboutPage";
import TeamPage from "../team/TeamPage";
import FAQPage from "../faq/FAQPage";
import RoadMapPage from "../roadmap/RoadMapPage";
import BenefitsPage from "../benefits/BenefitsPage";
import GalleryPage from "../gallery/GalleryPage";
import { useLocation } from "react-router-dom";

const Home = ({ anchor }) => {
  const [hash, setHash] = useState(window.location.hash);
  const location = useLocation();
  React.useEffect(() => { 
    setHash(location.hash);
  }, [location]);
  return <HomePage anchor={hash}/>
}

class HomePage extends React.Component {
  anchorDict = {
    "#about": React.createRef(),
    "#gallery": React.createRef(),
    "#benefits": React.createRef(),
    "#roadmap": React.createRef(),
    "#faq": React.createRef(),
    "#team": React.createRef()
  };

  state = { anchor: this.props.anchor };

  static getDerivedStateFromProps(props, state) {
    return { ...state, anchor: props.anchor };  
  }
  
  componentDidMount() {
    this.scrollToAnchor()
  }

  componentDidUpdate() {
    this.scrollToAnchor()
  }

  scrollToAnchor() {
    var anchor = this.state.anchor;
    if (this.anchorDict[anchor] !== undefined && this.anchorDict[anchor].current !== null) {
      var ref = this.anchorDict[anchor].current;
      ReactDOM.findDOMNode(ref).scrollIntoView(true);
    }
  }

  render() {
    return <div>
      <div className="container">
        <img src="./coming-soon.gif" className="img m-5 mt80" alt="coming-soon"/>
      </div>
      <KarmaBand/>
      <AboutPage ref= { node => { this.anchorDict["#about"].current = node }}/>
      <GalleryPage ref= { node => { this.anchorDict["#gallery"].current = node }}/>
      <BenefitsPage ref= { node => { this.anchorDict["#benefits"].current = node }}/>
      <KarmaBand/>
      <RoadMapPage ref= { node => { this.anchorDict["#roadmap"].current = node }}/>
      <FAQPage ref={ node => { this.anchorDict["#faq"].current = node }}/>
      <KarmaBand/>
      <TeamPage ref={ node => { this.anchorDict["#team"].current = node }}/>
    </div>
  }
}

class KarmaBand extends React.Component {
  render() {
    return <div className="band" style={{lineHeight:"1.5"}}>
      <marquee className="mt8" behavior="scroll" scrollamount="20" direction="left">
        <h4 style={{margin: 0}}>RTSQZGVLB7Q8RY48LNNHXKXHELP4S25CLJZTKWK7ZLSSPPHD2XRT2MUX7KWKFEQ6LGJS9V5856FC3HXY35WASSFBNH32GP9 CRYPTVZ ALL OVER THE METAVERSE. AF7XF9887764KL8RUTC7DULCW3F9ERJ4TSUJJKLGPYVQ4TL8KMXRB5U7HKZAG4RLZHWUEKDPDNMKZXZH5SWP94DG6FCZPQQZLME83ZQHLQJANWP8YJQ7K6QMF45C4UNPHVYAPX3Q2XZE9J5QKBN66LNFZ7GDDBJ935ZUZ7NMJWAMEXKSRDPLXZA8PRYULUGG5DSBNV92B6RVPVWU974E99DM252K56477RWEE CRYPTVZ ALL OVER THE METAVERSE. A3QQTV5QMF9KRQKQKFB6MS73AV WGMI CSYU8C HELLO SER WHALE?H6XE68D6JARZ6AJX5T3FB7GTCNNH8A5R9H6PYUT87R6CUKRWXQYPLDFVWA2BG9KY5AFCCQXTQURJHR63FQQQPDS476TCAQ4UYT4WG7EFZNBLTP2B978W7YKMCS6HTQLHQXJRF4MKM9LKTTH8SZTJA23KP2GEMX35L7BRKS4SDGAL68P9HVTCYXCX8BLMEADSS8MC6GPR2SA5BNMWTYYTEQ7J533CXC8VVMA74YFM4KF4M GM XCTC2JFPD5TAHMMPGX4CZKTQ3W2LYXVYU2 CRYPTVZ ALL OVER THE METAVERSE. FCBJ6QXY9LHFZSVHM7XAGNN5B3WZLSC8VKU9FCJSTJFZJKYADDXCE5U4TJ5NUXSFJYU5V9ANAQS3U7MJLRZ2DYN7N6SAAQ24A2Y4VQSLGTR7STPXKFM52FB CRYPTVZ ALL OVER THE METAVERSE. V4HBMYGG2TP6Q4RZ4A86HN4PN GN FRENS ERUY7ZQFZTZ5NAD8TP5Y5YTBY3CZCE6QMP97R93KFKPAMUQDR7GUBKDVCBNAFMXE4SAKEUTX4HXD2WBHMDVLBCZ4MG94LHZZCJH4U8ZB7N69AQ698RPVECP8A2M7XZJ476YJZQVN6CKSPNAEVAKQA9GJRDMP6RE</h4>
      </marquee>
    </div> 
  }
}

export default Home;
