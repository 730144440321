import React from "react";

class BenefitsPage extends React.Component {
  render() {
    return <div className="section" id="benefits">
    <div className="text-container2">
      <h1>Why buy Cryptvz?</h1> <br/>
      <div className="icon-list">
        <div className="icon-item">
          <img src="bullet/vote.png" width="120" alt="coming-soon"/>
          <h2>Governance</h2>
          <p>Through voting the community decides how the project is going to evolve. Each assets grants you one voting right.
          </p>
        </div>

        <div className="icon-item">
          <img src="bullet/copy-rights.png" width="120" alt="coming-soon"/>
          <h2>Commercial Rights</h2>
          <p>With the purchase of a CRYPTV holders are granted commercial rights for their assets and all the traits within it.</p>
        </div>

        <div className="icon-item">
          <img src="bullet/blabla.png" width="120" alt="coming-soon"/>
          <h2>Community Hub</h2>
          <p><a className="link" href="https://discord.com/invite/GSHMAPUFBG" target="blank">Discord</a> will be our exchange ground for hot nft topics and alpha insights.</p>
        </div>
      </div>
      <div className="icon-list">
        <div className="icon-item"><img src="bullet/airdrop.png" width="120" alt="coming-soon"/>
          <h2>Benefits</h2>
          <p>We all love surprises, so art drops and games will make the news. </p>
        </div>

        <div className="icon-item">
          <img src="bullet/wow.png" width="120" alt="coming-soon"/>
          <h2>Merchandise</h2>
          <p>Our artworks are used for fancy merch, stay tuned to get your hands on our pieces.</p>
        </div>

        <div className="icon-item">
          <img src="bullet/token.png" width="120" alt="coming-soon"/>
          <h2>Utility</h2>
          <p>A whole ecosystem will be build which is going to benefit holders of CRYPTVZ.</p></div>
        </div>
      </div>
    </div>
  }
}

export default BenefitsPage;