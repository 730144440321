import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

class FAQPage extends React.Component {

  constructor() {
    super();
    this.state = { selections: ['0'] }
    this.didChangeAccordionSelection = this.didChangeAccordionSelection.bind(this);
  }

  didChangeAccordionSelection(selections) {
    this.setState({ selections: selections })
  }
  
  render() {
    return <div className="container2 g2" id="faq">
    <div className="wrapper">
      <h1>FAQ</h1>
      <div className="image">
      </div>  
      <div>
      <Accordion className="accordion" allowZeroExpanded preExpanded={['0']} onChange={this.didChangeAccordionSelection}>
        {
          faqItemsData.map((data, index) => {
            var isActive = this.state.selections.includes(index.toString())
            var isActiveStyle = isActive ? " is-active" : ""
            return <AccordionItem key={index.toString()} uuid={index.toString()}>
                <AccordionItemHeading>
                    <AccordionItemButton className={ "accordion__button" + isActiveStyle}>
                        {data.question}
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className={"accordion__body" + isActiveStyle}>
                  <div style={{padding: "20px"}}>
                    {data.answer}
                  </div>
                </AccordionItemPanel>
            </AccordionItem>
          })
        }
      </Accordion>
    </div>
    </div>
  </div> 
  }
}

class QuestionAndAnswer {
  constructor(question, answer) {
    this.question = question;
    this.answer = answer;
  }
}

const faqItemsData = [
  new QuestionAndAnswer(
    "How to Mint?",
    (<p style={{margin: 0}}>
      1. Allowlist: Be one of the early adopters and use the possibility to safe your spot. Make sure to head over to <a className="link" href="https://discord.com/invite/GSHMAPUFBG" target="blank">Discord</a> and <a className="link" href="https://twitter.com/cryptvz" target="blank">Twitter</a> to not miss any announcements.<br/><br/>
    
      2. Public Sale:
      When public minting will be live we will try that everyone has the possibility to mint the token on the website for the price of TBA. We still haven't decided on the total number of allowlist spots yet but they will be rare.
    </p>)
  ), new QuestionAndAnswer(
    "What's an NFT?",
    (<p style={{margin: "0"}}>An NFT is the abbreviation for non-fungible-token and essentially means that you will own a one of a kind token with unique artwork.<br/><br/> Be involved in the future of the internet - Think of it as a cool way of being part of a big movement in the history of art and tech.</p>)
  ), new QuestionAndAnswer(
    "Who created CRYPTVZ?",
    (<p style={{margin: "0"}}>Essentially being the idea of @AlienMutant, a multidisciplinary artist that has just emerged into the NFT space.</p>)
  ), new QuestionAndAnswer(
    "How can I aquire a CRYPTV?",
    (<p style={{margin: "0"}}>First you will need a web3 ethereum wallet in order to acquire a CRYPTV. Here you will find all the information you need:<a href="https://metamask.io/" target="blank" className="link"> https://metamask.io/. </a><br/><br/>Second you will be able to mint from our page (dates tba) or buy on <a className="link" href="https://opensea.io" target="blank">Opensea</a></p>)
  ), new QuestionAndAnswer(
    "Wen mint?",
    (<p style={{margin: "0"}}>Minting will start in early 2022. Exact dates will be announced on <a className="link" href="https://twitter.com/cryptvz" target="blank">Twitter</a> and <a className="link" href="https://discord.com/invite/GSHMAPUFBG" target="blank">Discord</a>.</p>)
  ), new QuestionAndAnswer(
    "How rare is my CRYPTV?",
    (<p style={{margin: "0"}}>If it looks rare it is rare. Future rarity tools will help you identify.</p>)
  ), new QuestionAndAnswer(
    "How is the project going to evolve?",
    (<p style={{margin: "0"}}>The community is king, you are fully going to decide how this project is going to evolve. One voting right for one asset. <br/></p>)
  ), new QuestionAndAnswer(
    "What does my CRYPTV do for me?",
    (<p style={{margin: "0"}}>Besides being an amazing PFP it will double as:<br/>- entry to every further project<br/>- Access to IRL events<br/></p>)
  )
];

export default FAQPage;