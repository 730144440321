import React from "react";
import { Link } from "react-router-dom";

class SmallScreenNavigation extends React.Component {
  
  constructor(props) {
    super(props)
    this.state = { isMobileNavigationVisible: false };
    this.toggleNav = this.toggleNav.bind(this);
    this.closeNav = this.closeNav.bind(this);
  }

  render() {
    return <div className="small-screen">
      <span onClick={this.toggleNav}>
        <button id="category" className={this.state.isMobileNavigationVisible ? "menu opened" : "menu"} aria-label="Main Menu" aria-expanded={this.state.isMobileNavigationVisible}>
          <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100">
            <path className="line line1" d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058" />
            <path className="line line2" d="M 20,50 H 80" />
            <path className="line line3" d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942" />
          </svg>
        </button>
      </span>
      <div className="dropdown-content">
        <ul id="mobile-view" style={{ display: this.state.isMobileNavigationVisible ? "block" : "none"}} onClick={this.closeNav}>
          <li><Link className="navlink" to="/#about">About</Link></li>
          <br/>
          <li><Link className="navlink" to="/#gallery">Gallery</Link></li>
          <br/>
          <li><Link className="navlink" to="/#benefits">Benefits</Link></li>
          <br/>
          <li><Link className="navlink" to="/#roadmap">Roadmap</Link></li>
          <br/>
          <li><Link className="navlink" to="/#faq">FAQ</Link></li>
          <br/>
          <li><Link className="navlink" to="/#team">Team</Link></li>
          <br/>
          <li><Link className="navlink" to="/screens">Screens</Link></li>
          <br/>
          <div className="burger"/>
        </ul>
      </div>
    </div>
  }

  toggleNav() {
    this.setState(prevState => ({
      isMobileNavigationVisible: !prevState.isMobileNavigationVisible
    }));
  }
  
  closeNav() {
    this.setState(prevState => ({
      isMobileNavigationVisible: false
    }));
  }
}

class NavigationBar extends React.Component {
  render() {
    return <div className="nav">
    <ul className="desktop-view">
      <li><Link className="navlink" to="/#about">About</Link></li>
      <li><Link className="navlink" to="/#gallery">Gallery</Link></li>
      <li><Link className="navlink" to="/#benefits">Benefits</Link></li>
      <li><Link className="navlink" to="/#roadmap">Roadmap</Link></li>
      <li><Link className="navlink" to="/#faq">FAQ</Link></li>
      <li><Link className="navlink" to="/#team">Team</Link></li>
      <li><Link className="navlink" to="/screens">Screens</Link></li><br/>
    </ul>
    <SmallScreenNavigation/>
  </div>
  }
}

export default NavigationBar;