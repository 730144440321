import React from "react";

class GalleryPage extends React.Component {
  render() {
    return <div className="section" id="gallery">
    <div className="text-container">
      <h1>Gallery</h1>
      <img src="gallery/01.png" width="31%" alt="coming-soon"/>
      <img src="gallery/02.png" width="31%" alt="coming-soon"/>
      <img src="gallery/03.png" width="31%" alt="coming-soon"/>
      <br/>
      <img src="gallery/04.png" width="31%" alt="coming-soon"/>
      <img src="gallery/05.png" width="31%" alt="coming-soon"/>
      <img src="gallery/06.png" width="31%" alt="coming-soon"/>
      <br/>
      <img src="gallery/07.png" width="31%" alt="coming-soon"/>
      <img src="gallery/08.png" width="31%" alt="coming-soon"/>
      <img src="gallery/09.png" width="31%" alt="coming-soon"/>
      <br/>
      <p><br/>(View the whole collection after mint on OpenSea)</p>
    </div>
  </div>
  }
}

export default GalleryPage;